/*eslint no-case-declarations: 0*/
import { pushGAEvent } from '@analytics/index';
import commService from '@comm_service/index';
import { SDKMode, SDKProps } from '@rokt/types/sdk';
import roktProxy from '@rokt_proxy/RoktProxy';
import { CallbackTypes, InboundEvents } from '@shared/types/commService';
import { getCookie, parseToObject } from '@shared/utils';
import { ISponsParkwhizSdk } from 'types/sdk/';

import { loadSponsorshipUI } from './sponsorship_ui';
import sponsorshipProxy from './sponsorship_ui/SponsorshipProxy';

class SponsParkwhizSdk implements ISponsParkwhizSdk {
  roktSdkProps: SDKProps | null = null;

  async init(props: SDKProps) {
    this.validateMode(props);

    if (props.isSponsorshipWidget) {
      this.initSponsorshipUI(props);
      return sponsorshipProxy;
    }

    const filteredProps: SDKProps = {};
    for (const [key, prop] of Object.entries(props)) {
      if (key == 'onUpdateItem') {
        roktProxy.onUpdateItemSubscription(prop);
        filteredProps.onUpdatePresent = true;
      } else if (key == 'onInitError') {
        commService.registerOnInitError(prop);
        filteredProps.onInitErrorPresent = true;
      } else if (key == 'onFanaticsDeliveryUpdate') {
        roktProxy.onFanaticsDeliveryUpdate(prop);
        filteredProps.onFanaticsDeliveryUpdatePresent = true;
      } else if (key == 'onReadMoreCallback') {
        roktProxy.onReadMoreCallback(prop);
        filteredProps.onReadMoreCallbackPresent = true;
      } else {
        filteredProps[key] = prop;
      }
    }
    filteredProps.noDeviceId = this.isOptanonConsentCookieSet();
    this.roktSdkProps = filteredProps;
    roktProxy.registerCallbacksMessage(this.registerCallbacks);

    commService.handleExecuteCallback = this.executeCallback;
    return new Promise((resolve) => {
      this.startRendering().then(() => {
        this.initData().then(() => {
          resolve(roktProxy);
        });
      });
    });
  }

  async initSponsorshipUI(props: SDKProps): Promise<{ isSponsorshipWidgetRendered: boolean }> {
    loadSponsorshipUI(props);
    return { isSponsorshipWidgetRendered: true };
  }

  validateMode(props: SDKProps) {
    switch (props.mode) {
      case SDKMode.EPHEMERAL:
        if (!props.roktTagId || props.sponsId)
          throw new Error('Ephemeral mode requires only roktTagId');
        break;
      default:
        // Default is SDKMode.TRANSACTIONAL
        if (!props.sponsId) throw new Error('Transactional mode requires sponsId');
        break;
    }
  }

  async startRendering() {
    return new Promise((resolve) => {
      commService.render(resolve, pushGAEvent);
    });
  }

  initData() {
    return new Promise((resolve) => {
      commService.init(this.roktSdkProps, resolve);
    });
  }

  registerCallbacks() {
    commService.routeEventMessage({
      data: {
        event: InboundEvents.PARKWHIZ_SDK_REGISTER_CALLBACKS,
        message: null,
      },
    });
  }

  executeCallback(name: string, data: any) {
    const callback = roktProxy.getValue(name);
    if (callback) {
      switch (name) {
        case CallbackTypes.ON_UPDATE_ITEM_SUBSCRIPTION:
          callback(data);
          break;
        case CallbackTypes.ON_UPDATE_ITEM:
          const { updatedItem, oldItem } = data;
          callback(updatedItem, oldItem);
          break;
        case CallbackTypes.ON_FAILURE:
          callback(data);
          break;
        case CallbackTypes.ON_DELIVERY_DATA_UPDATED:
          callback(data);
          break;
        case CallbackTypes.ON_READ_MORE:
          callback(data);
          break;
      }
    } else {
      throw new Error('Invalid callback name');
    }
  }

  deliveryMethodChange(cartItemIds: string[], message: any = null) {
    const params = { filter: { cartItemIds }, message };
    commService.routeEventMessage({
      data: {
        event: InboundEvents.PARKWHIZ_SDK_ON_DELIVERY_METHOD_CHANGE,
        message: params,
      },
    });
  }

  deliveryCountryChange(countryCode: string, alertMessage: any = null) {
    const params = { countryCode, alertMessage };
    commService.routeEventMessage({
      data: {
        event: InboundEvents.PARKWHIZ_SDK_ON_DELIVERY_COUNTRY_CHANGE,
        message: params,
      },
    });
  }

  hideMessage() {
    commService.routeEventMessage({
      data: {
        event: InboundEvents.PARKWHIZ_SDK_ON_HIDE_MESSAGE,
        message: null,
      },
    });
  }

  updateCartItem(cartItemId: string, quantity: number) {
    const params = { cartItemId, quantity };
    commService.routeEventMessage({
      data: {
        event: InboundEvents.PARKWHIZ_SDK_ON_UPDATE_CART_ITEM,
        message: params,
      },
    });
  }

  isOptanonConsentCookieSet() {
    const optanonConsentCookie = parseToObject(getCookie('OptanonConsent'));

    return optanonConsentCookie?.groups?.includes('C0004:0') ?? false;
  }
}

window.SponsParkwhizSdk = new SponsParkwhizSdk();

export default SponsParkwhizSdk;
